import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  of,
  Subscription,
  throwError,
} from 'rxjs';
import { map, catchError, switchMap, finalize, delay } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthModel } from '../models/auth.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SingUp } from '../models/sing-up.model';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthResponse } from '../components/models/auth.login.response.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ValidTokenResponse } from '../components/models/auth.token.valid.response.model';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import { ResetPassword } from '../models/reset-password.model';
import { UserProjectPermission } from '../../warmi/my-account/model/user-project-permissions.model';
import { CreatePasswordRequest } from '../components/models/auth.createpassword.request.model';
import { AuthHTTPService } from './auth-http/auth-http.service';
import { PlanConfiguration } from '../../warmi/strategies/model/plan.model';
import { MoonflowUtilService } from '../../utils/service/moonflow-util.service';

export type UserType = UserModel | undefined;

const API_LOGIN_URL = `${environment.backendUri}/auth/login`;
const API_LOGOUT_URL = `${environment.backendUri}/auth/logout`;
const API_REFRESH_URL = `${environment.backendUri}/auth/refresh`;
const API_VALID_TOKEN_URL = `${environment.backendUri}/auth/token/isvalid`;
const API_AUTH_TOKEN_KEY = `${environment.authTokenKey}`;
const API_FORGOT_PASSWORD = `${environment.backendUri}/auth/forgot`;
const API_RESET_PASSWORD = `${environment.backendUri}/auth/reset-password`;
const API_CREATE_PASSWORD = `${environment.backendUri}/auth/create-password`;
const API_SEND_EMAIL_FOR_CONFIRMATION = `${environment.backendUri}/auth/sendemailconfirmation`;
const API_CREATE_PASSWORD_AND_USER = `${environment.backendUri}/auth/setpassword`;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];

  private API_URL = `${environment.API_URL}/user`;

  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private helper = new JwtHelperService();

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  private loadingAsideHeaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  loadingAsideHeaderSubject$ = this.loadingAsideHeaderSubject.asObservable();

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private http: HttpClient,
    private authHttpService: AuthHTTPService,
    private router: Router,
    private cookieService: CookieService,
    private _moonflowUtilService : MoonflowUtilService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string, twoFactorCode?: string, closeOtherSession? : boolean): Observable<AuthResponse> {
    this.clearCookiesAndLocalStorage();
    let requestBody : {
      username : string;
      password : string;
      twoFactorCode? : string;
      closeOtherSession? : boolean;
    } = { username: email?.toLowerCase(), password}
    if(twoFactorCode != null && twoFactorCode.length > 0) requestBody.twoFactorCode = twoFactorCode;
    if(closeOtherSession) requestBody.closeOtherSession = true;
    return this.http
      .post<HttpResponse<AuthResponse>>(
        API_LOGIN_URL,
        requestBody,
        { observe: 'response' }
      )
      .pipe(
        map((response) => {

          if(response.headers.get(environment.refreshJwtHeader)) this.saveRefreshTokenInLocalstorage(response.headers.get(environment.refreshJwtHeader))

          return response.body;
        }),
        catchError(this.handleError('login', []))
      );
  }

  isValidToken(): Observable<ValidTokenResponse> {
    return this.http.post<ValidTokenResponse>(API_VALID_TOKEN_URL, {});
  }

  logout() {
    return this.http
      .post<void>(API_LOGOUT_URL, null)
      .pipe(catchError(this.handleError('logout', []))).subscribe(data => this.clearCookiesAndLocalStorage(), err => this.clearCookiesAndLocalStorage());
  }

  clearCookiesAndLocalStorage() : void{
    localStorage.removeItem(API_AUTH_TOKEN_KEY);
    sessionStorage.removeItem("ALERT_NOTIFICATIONS_CLOSED");
    localStorage.clear();
    this.cookieService.deleteAll();
    this.cookieService.delete(environment.backofficeProjectCookieKey);
    this.cookieService.delete(environment.backofficeCookieKey);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  refresh(): Observable<{ token: string }> {
    let headers = new HttpHeaders();
    headers = headers.append(environment.refreshJwtHeader, this.getRefreshTokenFromLocalstorage() || "")
    headers = headers.append("Authorization", `Bearer ${this.getAuthToken() || ""}`)
    return this.http
      .get<HttpResponse<{ token: string }>>(API_REFRESH_URL, {
        observe: 'response',
        headers : headers
      })
      .pipe(
        map((response) => {

          return response.body;
        }),
        catchError(this.handleError('refresh', []))
      );
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  // registration(user: UserModel): Observable<any> {
  //   this.isLoadingSubject.next(true);
  //   return this.authHttpService.createUser(user).pipe(
  //     map(() => {
  //       this.isLoadingSubject.next(false);
  //     }),
  //     switchMap(() => this.login(user.email, user.password)),
  //     catchError((err) => {
  //       console.error('err', err);
  //       return of(undefined);
  //     }),
  //     finalize(() => this.isLoadingSubject.next(false))
  //   );
  // }

  singUp(t: SingUp) {
    this.isLoadingSubject.next(true);
    return this.http.post<SingUp>(`${this.API_URL}/singup`, t).pipe(
      // delay(3000),
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    let forgotPasswordRequest = {
      email: ''
    };
    forgotPasswordRequest.email = email;
    return this.http.post<any>(API_FORGOT_PASSWORD, forgotPasswordRequest).pipe(
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  sendEmailForConfirmation(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    let forgotPasswordRequest = {
      email: ''
    };
    forgotPasswordRequest.email = email;
    return this.http.post<any>(API_SEND_EMAIL_FOR_CONFIRMATION, forgotPasswordRequest).pipe(
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  resetPassword(resetPassword: ResetPassword): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.post<any>(API_RESET_PASSWORD, resetPassword).pipe(
      catchError((err) => {
        return throwError(err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public setAuthToken(auth: AuthResponse): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(API_AUTH_TOKEN_KEY, auth.token);
      return true;
    }
    return false;
  }

  public reloadAsideAndHeader(){
    this.loadingAsideHeaderSubject.next(true);
    setTimeout(() => {
      this.loadingAsideHeaderSubject.next(false);
    }, 10);

  }


  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  decryptedToken(): {
    sub: string;
    roleId: any;
    permissions : UserProjectPermission[] | null;
    projectId?: string | null;
    canCall : boolean;
    userId : string;
    projectType : string;
    timeZone?: string | null;
    countryId: string;
    economicSectorId : number | null;
    chargeEntity : string | null;
    primaryCurrency: string | null;
    secondaryCurrency : string | null;
    pageComponentsConfiguration : string | null;
    planConfiguration : PlanConfiguration | null;
    allowIndicators : boolean | false;
    callProvider : string | null;
    isSuperadmin : boolean | false;
  } | null {
    return this._moonflowUtilService.decryptedToken();
  }

  getActiveProjectId(cookieKey?: string) {
    return this._moonflowUtilService.getActiveProjectId(cookieKey);
  }

  isTokenExpired() {
    const token = localStorage.getItem(environment.authTokenKey);

    if (token === 'undefined') {
      localStorage.removeItem(environment.authTokenKey);
      return true;
    }

    return this.helper.isTokenExpired(token || undefined);
  }

  /*
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error);
      // console.error(error.error);
      result =
        error.status === 0 ||
        (error?.error &&
          error.error?.message &&
          error?.error?.message.indexOf(' ') > -1 &&
          error?.error?.message?.split('.').length >= 3)
          ? error.error.message
          : error.status === 500
          ? 'SERVERERROR'
          : null;

      if (
        (
          error?.error &&
          error?.error?.message &&
          error?.error?.message?.indexOf('AUTH.VALIDATION') != -1
        )

        ||

        (
          error?.error &&
        error?.error?.error &&
        error?.error?.error?.indexOf('AUTH.VALIDATION') != -1
        )
      ) {
        result = error?.error?.message || error?.error?.error;
      }
      // Let the app keep running by returning an empty result.
      return of({ error: result });
    };
  }

  public saveRefreshTokenInLocalstorage(refreshToken : string | null){
    if(!refreshToken) return;
    const keys = environment.refreshTokenLocalstorageKeys.split(",");
    let refreshTokenKeys : any = {};
    let substringToken : string | null = refreshToken+"";
    let stringTruncateLengthExpected = Math.floor(refreshToken.length / keys.length);
    keys.forEach((e : string) =>{
      if(substringToken?.length){
        refreshTokenKeys[e] = substringToken.substring(0, stringTruncateLengthExpected);
        substringToken = substringToken.substring(stringTruncateLengthExpected,substringToken.length);
        if(substringToken.length < stringTruncateLengthExpected){
          refreshTokenKeys[e] = refreshTokenKeys[e]+substringToken;
          substringToken = null;
        }
        if(refreshTokenKeys[e]) refreshTokenKeys[e] = btoa(refreshTokenKeys[e]);

      }
    })

    let splitedToken = "";
    keys.forEach((e : string) =>{
      if(refreshTokenKeys[e]) splitedToken = splitedToken + refreshTokenKeys[e];
    })
    keys.forEach((e : string) =>{
      localStorage.setItem(e, refreshTokenKeys[e]);
    })

  }

  private getRefreshTokenFromLocalstorage() : string | null{
    const keys = environment.refreshTokenLocalstorageKeys.split(",");
    let splitedToken : string | null = null;
    keys.forEach((e : string) =>{
      if(localStorage.getItem(e)) splitedToken = (splitedToken || "") + atob(localStorage.getItem(e) || '');
    })
    return splitedToken;
  }

  public getAuthToken() : string | null {
    return localStorage.getItem(API_AUTH_TOKEN_KEY);
  }

  createPasswordAndUser( data : CreatePasswordRequest): Observable<AuthResponse> {
    return this.http.post<void>(API_CREATE_PASSWORD_AND_USER, data,
      { observe: 'response' }).pipe(
        map((response) => {
          if(response.headers.get(environment.refreshJwtHeader)) this.saveRefreshTokenInLocalstorage(response.headers.get(environment.refreshJwtHeader))
          return response.body;
        }),
        catchError(this.handleError('login', []))
      );
  }

  insertUsetifulScript(){
    let id = "usetiful-script";
    if(!document.getElementById(id)){
      let a = document.getElementsByTagName('head')[0];
      let r = document.createElement('script');
      r.id = id;
      r.async = true;
      r.src = "https://www.usetiful.com/dist/usetiful.js";
      r.setAttribute('id', 'usetifulScript');
      r.dataset.token = "39f2148a7ced77e64fcee3a0a357acc9";
      a.appendChild(r);
    }
  }

  getPlanConfigurationId() : number | undefined{
    return this.decryptedToken()?.planConfiguration?.planId;
  }

}
