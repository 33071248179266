export class TemplateConfiguration {
  subject: string | null;
  body: string;
  headerImg: string | null;
  whatsappButtons: WhatsappButton[];
  payButtonTextColor: string;
  payButtonBackgroundColor: string;
  contactResponseActionButtonTextColor: string;
  contactResponseButtonBackgroundColor: string;
}

export class WhatsappButton {
  type: string;
  buttonText: string;
  url: string;
}
