import { PaginatorResponse } from "src/app/modules/utils/paginator.response";
import { DocumentType } from "./documentType.model";

export class InteractionStatus{
    id : string;
    name : string;
    translationKey : string;
    callManagementActionType? : string;
    fieldManagementActionType? : string;
}

export enum InteractionStatusIds{
    INTERACTION_STATUS_SEND = 1,
    INTERACTION_STATUS_DELIVERY = 2,
    INTERACTION_STATUS_OPEN = 3,
    INTERACTION_STATUS_GENERATED = 4,
    INTERACTION_STATUS_PROGRAMMED = 5,
    INTERACTION_STATUS_FAILED = 6,
    INTERACTION_STATUS_CALL_MANAGMENT_CONTACT = 7,
    INTERACTION_STATUS_CALL_MANAGMENT_NOT_CONTACT = 8,
    INTERACTION_STATUS_CALL_MANAGMENT_TO_MANAGE = 9,
    INTERACTION_STATUS_SENT_NO_STATUS = 13,
    INTERACTION_STATUS_CALL_MANAGMENT_PHONECALL_NO_MANAGEMENT = 14,
    INTERACTION_STATUS_CALL_MANAGEMENT_INBOUND_CONTACT = 12,
    INTERACTION_STATUS_NO_RESPONSE_FROM_PROVIDER = 16,
    INTERACTION_STATUS_CONTACT = 17,
    INTERACTION_STATUS_NOT_CONTACT = 18,
    INTERACTION_STATUS_INBOUND = 19,
    INTERACTION_MISS_OUT = 10,
    WEB_FORM_LOGIN = 300,
    WEB_FORM_PAY_BUTTON = 301,
    WEB_FORM_PAYMENT_METHOD_SELECTED = 302,
    WEB_FORM_GO_TO_PAY = 303,
    WEB_FORM_SEND_INSTRUCTIONS = 304,
    WEB_FORM_ALREADY_PAID = 305,
    WEB_FORM_SEND_PAYMENT_INFORMATION = 306,
    WEB_FORM_PAYMENT_COMMITMENT_BUTTON = 307,
    WEB_FORM_PAYMENT_COMMITMENT_GENERATED = 308,
    WEB_FORM_TALK_WITH_ASSISTANT = 309,
    WEB_FORM_PAY_NOW = 310,
    WEB_FORM_OTHER_PAYMENT_METHODS = 311,
    VERIFICATION_PROCESS_ALERT = 20,
    VERIFICATION_PROCESS_DENIED = 21,
    VERIFICATION_PROCESS_ACTIVE = 22
}


