import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense(environment.syncfusionLicenseKey);

if (environment.production) {
  enableProdMode();

  // Insertar el script de Google Tag Manager solo en producción
  (function(w: any, d: Document, s: string, l: string, i: string) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s) as HTMLScriptElement;  // Especificar el tipo de elemento
    
    const dl = l != 'dataLayer' ? '&l=' + l : '';
    
    j.async = true;  // Ahora puedes usar la propiedad async
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;  // La propiedad src ya está disponible
    
    if (f && f.parentNode) {
      f.parentNode.insertBefore(j, f);  // Asegúrate de que f.parentNode no sea null
    }
  })(window, document, 'script', 'dataLayer', 'GTM-WNKRBZ5');

  // Insertar el <noscript> con el iframe en el body solo en producción
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WNKRBZ5"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.body.appendChild(noscript);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
